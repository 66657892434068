.page404 {
  display: grid;
  text-align: center;
  place-content: center;
  min-height: 100vh;
  font-size: 20px;
}

.page404 h1 {
  font-size: 72px;
  line-height: 72px;
  margin: 0;
}
