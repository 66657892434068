.cards {
  padding: 2%;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  user-select: none;
}

.card-item {
  position: relative;
  height: 0;
  padding-top: 100%;
  cursor: pointer;
}

.card-item__img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: 0.15s ease-in-out;
  will-change: transform;
}
.card-item__img:hover {
  transform: scale(0.9);
}
