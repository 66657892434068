.pop-up {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-image: url('../../assets/popup.png');
  background-size: 95%;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.25);
  background-position-x: center;
  will-change: transform;
  transition: transform 0.3s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0;
  font-family: 'Smokum', cursive;
}

.pop-up__header {
  width: 100%;
  height: auto;
  pointer-events: none;
  user-select: none;
}

.pop-up__text-wrapper {
  margin-top: 26px;
}

.pop-up--is-open {
  display: block;
  animation-name: anim-popup;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.prize-title,
.prize-subtitle {
  position: relative;
  padding: 0 15%;
  font-size: calc(var(--prize-size, 120) * 0.7px);
  line-height: calc(var(--prize-size, 120) * 0.7px);
  font-weight: 900;
  color: var(--prize-title-color);
  background-image: -webkit-linear-gradient(var(--prize-title-color), var(--prize-title-color-dark));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.prize-subtitle {
  font-size: calc(var(--prize-subtitle-size, 80) * 0.7px);
  line-height: calc(var(--prize-subtitle-size, 80) * 0.7px);
  color: var(--prize-subtitle-color);
  background-image: -webkit-linear-gradient(var(--prize-subtitle-color), var(--prize-subtitle-color-dark));
  padding-bottom: 24px;
}

.prize-title__shadow,
.prize-subtitle__shadow {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 15%;
  font-size: calc(var(--prize-size, 120) * 0.7px + 3px);
  transform: scale(1.02);
  color: #000;
  -webkit-text-fill-color: initial;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  opacity: 0.3;
  filter: blur(2px);
}

.prize-subtitle__shadow {
  font-size: calc(var(--prize-subtitle-size, 80) * 0.7px + 2px);
}

.pop-up-info {
  padding: 0 15%;
  color: #fff;
  font-size: calc(var(--info-size, 20) * 0.8px);
  font-family: Arial, Helvetica, sans-serif;
}

@media (min-width: 600px) {
  .prize-title {
    font-size: calc(var(--prize-size, 120) * 1px);
    line-height: calc(var(--prize-size, 120) * 1px);
  }
  .prize-title__shadow {
    font-size: calc(var(--prize-size, 120) * 1px + 3px);
    line-height: calc(var(--prize-size, 120) * 1px + 3px);
  }
  .prize-subtitle {
    font-size: calc(var(--prize-subtitle-size, 80) * 1px);
    line-height: calc(var(--prize-subtitle-size, 80) * 1px);
  }
  .prize-subtitle__shadow {
    font-size: calc(var(--prize-subtitle-size, 80) * 1px + 3px);
    line-height: calc(var(--prize-subtitle-size, 80) * 1px + 3px);
  }

  .pop-up-info {
    font-size: calc(var(--info-size, 20) * 1px);
  }
}

@media (min-width: 600px) and (max-height: 1000px) {
  .prize-title {
    font-size: calc(var(--prize-size, 120) * 0.7px);
    line-height: calc(var(--prize-size, 120) * 0.7px);
  }
  .prize-title__shadow {
    font-size: calc(var(--prize-size, 120) * 0.7px + 3px);
    line-height: calc(var(--prize-size, 120) * 0.7px + 3px);
  }
  .prize-subtitle {
    font-size: calc(var(--prize-subtitle-size, 80) * 0.7px);
    line-height: calc(var(--prize-subtitle-size, 80) * 0.7px);
  }
  .prize-subtitle__shadow {
    font-size: calc(var(--prize-subtitle-size, 80) * 0.7px + 3px);
    line-height: calc(var(--prize-subtitle-size, 80) * 0.7px + 3px);
  }
}

@media (min-width: 600px) and (max-height: 780px) {
  .prize-title {
    font-size: calc(var(--prize-size, 120) * 0.09vh);
    line-height: calc(var(--prize-size, 120) * 0.09vh);
  }
  .prize-title__shadow {
    font-size: calc(var(--prize-size, 120) * 0.09vh + 3px);
    line-height: calc(var(--prize-size, 120) * 0.09vh + 3px);
  }
  .prize-subtitle {
    font-size: calc(var(--prize-subtitle-size, 80) * 0.09vh);
    line-height: calc(var(--prize-subtitle-size, 80) * 0.09vh);
  }
  .prize-subtitle__shadow {
    font-size: calc(var(--prize-subtitle-size, 80) * 0.09vh + 3px);
    line-height: calc(var(--prize-subtitle-size, 80) * 0.09vh + 3px);
  }

  .pop-up-info {
    font-size: calc(var(--info-size, 20) * 0.8px);
  }
}
@media (min-width: 600px) and (max-height: 600px) {
  .pop-up-info {
    font-size: calc(var(--info-size, 20) * 0.7px);
  }
}
@media (max-width: 345px) {
  .prize-title {
    font-size: calc(var(--prize-size, 120) * 0.4px);
    line-height: calc(var(--prize-size, 120) * 0.4px);
  }
  .prize-title__shadow {
    font-size: calc(var(--prize-size, 120) * 0.4px + 3px);
    line-height: calc(var(--prize-size, 120) * 0.4px + 3px);
  }
  .prize-subtitle {
    font-size: calc(var(--prize-subtitle-size, 80) * 0.4px);
    line-height: calc(var(--prize-subtitle-size, 80) * 0.4px);
  }
  .prize-subtitle__shadow {
    font-size: calc(var(--prize-subtitle-size, 80) * 0.4px + 3px);
    line-height: calc(var(--prize-subtitle-size, 80) * 0.4px + 3px);
  }
  .pop-up-info {
    font-size: calc(var(--info-size, 20) * 0.65px);
  }
}
@media (max-width: 290px) {
  .prize-title {
    font-size: calc(var(--prize-size, 120) * 0.3px);
    line-height: calc(var(--prize-size, 120) * 0.3px);
  }
  .prize-title__shadow {
    font-size: calc(var(--prize-size, 120) * 0.3px + 3px);
    line-height: calc(var(--prize-size, 120) * 0.3px + 3px);
  }
  .prize-subtitle {
    font-size: calc(var(--prize-subtitle-size, 80) * 0.3px);
    line-height: calc(var(--prize-subtitle-size, 80) * 0.3px);
  }
  .prize-subtitle__shadow {
    font-size: calc(var(--prize-subtitle-size, 80) * 0.3px + 3px);
    line-height: calc(var(--prize-subtitle-size, 80) * 0.3px + 3px);
  }
}

@keyframes anim-popup {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
