.fancy-animation__wrapper {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  user-select: none;
  pointer-events: none;
}

.fancy-animation {
  position: absolute;
  z-index: 1;
}

.fancy-animation__animation {
  opacity: 0;
  transition: 1s ease-in-out;
  will-change: transform;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  user-select: none;
  pointer-events: none;
  z-index: 1;
}

.fancy-animation__title,
.fancy-animation__subtitle {
  text-align: center;
  font-size: calc(var(--prize-size, 120) * 0.5px);
  line-height: calc(var(--prize-size, 120) * 0.5px);
  font-weight: 900;
  color: var(--prize-title-color);
  text-transform: uppercase;
  text-shadow: 2px 3px 2px var(--prize-title-color-dark);
  user-select: none;
  pointer-events: none;
  font-family: var(--prize-title-font-family, "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif");
}

.fancy-animation__subtitle {
  font-size: calc(var(--prize-subtitle-size, 80) * 0.4px);
  line-height: calc(var(--prize-subtitle-size, 80) * 0.4px);
  font-family: var(--prize-subtitle-font-family, "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif");
}

.fancy-animation--is-open .fancy-animation__animation {
  transform: translate(-50%, -50%) scale(2);
  animation-name: anim-prize;
  animation-duration: 6s;
}

@keyframes anim-prize {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(2);
  }
}

@media (min-width: 600px) {
  .fancy-animation__title {
    font-size: calc(var(--prize-size, 120) * 0.8px);
    line-height: calc(var(--prize-size, 120) * 0.8px);
  }
  .fancy-animation__subtitle {
    font-size: calc(var(--prize-subtitle-size, 80) * 0.8px);
    line-height: calc(var(--prize-subtitle-size, 80) * 0.8px);
  }
}
