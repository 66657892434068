.header {
  padding-top: 24px;
  text-align: center;
  pointer-events: none;
  user-select: none;
}
.header-img {
  width: 100%;
  height: auto;
}
