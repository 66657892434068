:root {
  --text-color: #462214;
  --prize-size: 130;
  --prize-subtitle-size: 80;
  --prize-title-color: #ffd700;
  --prize-title-color-dark: #f5a322;
  --prize-subtitle-color: #ffd700;
  --prize-subtitle-color-dark: #f5a322;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.game {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url("./assets/background.png");
  background-size: cover;
}

@media screen and (min-width: 600px) {
  .game {
    height: 100vh;
    width: calc(1080 / 1920 * 100vh);
    padding: 0;
  }
}
