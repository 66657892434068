.error-message {
  display: flex;
  height: 30vh;
  padding: 24px;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
